import React from "react"
import { Alert } from "react-bootstrap"

export default function SuccessApi(props) {
  return (
    <Alert variant="success" data-test="success-message">
      <span className="alert-icon">
        <span className="sr-only">Success</span>
      </span>
      <div>{props.children}</div>
    </Alert>
  )
}
