import React from "react"
import { Alert } from "react-bootstrap"

export default function ErrorApi(props) {
  return (
    <Alert variant="danger" data-test="request-error">
      <span className="alert-icon">
        <span className="sr-only">Danger</span>
      </span>
      <div>
        {!props.children && (
          <p>
            Sorry, an expected error occurred, rest assured that our developers
            are working hard to repair it. You can try refreshing the page
          </p>
        )}
        {props.children}
      </div>
    </Alert>
  )
}
