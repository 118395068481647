const RETAIL = {
  name: 'R',
  label: 'Retail',
}
const TELECOM = {
  name: 'TWE',
  label: 'Telecom, Water, Energy',
}
const BANKING = {
  name: 'BF',
  label: 'Banking, Financial',
}
const AIRLINES = {
  name: 'A',
  label: 'Airlines',
}
const GOVERNMENT = {
  name: 'GA',
  label: 'Government/Administrations',
}
const HEALTH = {
  name: 'H',
  label: 'Health',
}
const PHARMACEUTICALS = {
  name: 'P',
  label: 'Pharmaceuticals',
}
const EDUCATION = {
  name: 'E',
  label: 'Education/Research',
}
const OTHER = {
  name: 'O',
  label: 'Other',
}
const INTERESTS = [
  RETAIL,
  TELECOM,
  BANKING,
  AIRLINES,
  GOVERNMENT,
  HEALTH,
  PHARMACEUTICALS,
  EDUCATION,
  OTHER,
]

module.exports = INTERESTS
