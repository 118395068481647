import React from "react"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import { Control, Form as ReduxForm, actions } from "react-redux-form"
import { Form, Col, Button } from "react-bootstrap"

import ENVIRONMENT from "../config/environment"
import { onboarded } from "../actions"
import { expectAuthenticationState } from "../components/authentication"
import Loading from "../components/loading"
import { ErrorList, validateNoSpecialCharAtBeginning } from "../components/validation"
import ErrorApi from "../components/error"
import SuccessApi from "../components/success"
import SEO from "../components/seo"

import Layout from "../components/layout"
import INTERESTS from "../../../backend/src/users/interests"

const ORANGE_LICENSE = {
  name: "ORANGE_LICENSE",
  label: "Orange employee license (1 year)",
}
const RESEARCH_LICENSE = {
  name: "RESEARCH_LICENSE",
  label: "Evaluation license for research purposes (3 months)",
}
const PROFESSIONAL_LICENSE = {
  name: "PROFESSIONAL_LICENSE",
  label: "Evaluation license for professional purposes (3 weeks)",
}

export const USAGE_TYPES = [
  ORANGE_LICENSE,
  RESEARCH_LICENSE,
  PROFESSIONAL_LICENSE,
]

const DEFAULT_LICENSE = PROFESSIONAL_LICENSE

const usageTypeListOrange = [ORANGE_LICENSE]
const usageTypeListExternal = [RESEARCH_LICENSE, PROFESSIONAL_LICENSE]

class UserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      errorMessage: null,
      requestInProgress: false,
      successful: false,
    }
  }

  render() {
    const { error, errorMessage, requestInProgress, successful } = this.state
    const usageTypeList = isOrangeMail(this.props.email)
      ? usageTypeListOrange
      : usageTypeListExternal
    return (
      <Layout>
        <SEO title="Welcome" />
        <h1>Welcome to Khiops</h1>
        {error && (
          <ErrorApi>
            <p>{errorMessage}</p>
          </ErrorApi>
        )}

        {successful && <SuccessApi>Your profile have been updated</SuccessApi>}
        <p>
          Before using our product, we would like to know a bit more about you,
          and also we would like to make sure you fully understood the{" "}
          <a target="_blank" href="/terms-and-conditions">
            terms of service
          </a>
          .
        </p>
        <ReduxForm
          model="onboardingForm.data"
          onSubmit={values => this.handleSubmit(values)}
          onChange={values => this.handleChange(values)}
        >
          <Form.Row>
            <Col md={10} lg={8} xl={6}>
              <Form.Group>
                <Form.Label htmlFor="firstName" className="is-required">
                  First name
                </Form.Label>
                <Control.text
                  className="form-control"
                  model=".firstName"
                  id="firstName"
                  validators={{
                    required: val => val && val.length,
                    isValid: val => validateNoSpecialCharAtBeginning(val || "")
                  }}
                  data-test="first-name"
                />
                <ErrorList
                  model=".firstName"
                  messages={{
                    required: "First name is required",
                    isValid: "First name cannot start with special characters"
                  }}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="lastName" className="is-required">
                  Last name
                </Form.Label>
                <Control.text
                  className="form-control"
                  model=".lastName"
                  id="lastName"
                  validators={{
                    required: val => val && val.length,
                    isValid: val => validateNoSpecialCharAtBeginning(val || "")
                  }}
                  data-test="last-name"
                />
                <ErrorList
                  model=".lastName"
                  messages={{
                    required: "Last name is required",
                    isValid: "Last name cannot start with special characters"
                  }}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="mail" className="is-required">
                  Email address
                </Form.Label>
                <Control.text
                  type="email"
                  className="form-control"
                  model=".email"
                  disabled
                  id="mail"
                  value={this.props.email}
                  data-test="email"
                />
                <ErrorList model=".email" messages={{}} />
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="usageType" className="is-required">
                  Usage type
                </Form.Label>
                <Control.select
                  type="field"
                  className="custom-select mr-sm-2"
                  model=".usageType"
                  defaultValue={
                    isOrangeMail(this.props.email)
                      ? ORANGE_LICENSE.name
                      : DEFAULT_LICENSE.name
                  }
                  disabled={isOrangeMail(this.props.email)}
                  id="usageType"
                  data-test="usage-type"
                >
                  {usageTypeList.map((option, index) => (
                    <option key={index} value={option.name}>
                      {option.label}
                    </option>
                  ))}
                </Control.select>
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="interest" className="mb-2">
                  Interests
                </Form.Label>
                {INTERESTS.map(interest => (
                  <div
                    key={interest.name}
                    className="custom-control custom-checkbox"
                    htmlFor={`onboarding-interests-${interest.name}`}
                  >
                    <Control.checkbox
                      type="checkbox"
                      className="custom-control-input"
                      model={`.interests.${interest.name}`}
                      id={`onboarding-interests-${interest.name}`}
                      value={false}
                      data-test={`interests-${interest.name}`}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`onboarding-interests-${interest.name}`}
                    >
                      {`${interest.label}`}
                    </label>
                  </div>
                ))}
              </Form.Group>
              <hr />
              <Form.Group className="mt-3">
                <div className="custom-control custom-checkbox">
                  <Control.checkbox
                    type="checkbox"
                    className="custom-control-input"
                    model=".acceptedConditions"
                    id="conditionsCheckbox"
                    value={false}
                    data-test="conditions"
                    validators={{
                      required: val => val === true,
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="conditionsCheckbox"
                  >
                    I accept the{" "}
                    <a target="_blank" href="/terms-and-conditions">
                      terms of service
                    </a>
                  </label>
                </div>
                <ErrorList
                  model=".acceptedConditions"
                  messages={{
                    required: "You need to accept the terms and conditions",
                  }}
                />
              </Form.Group>

              {!requestInProgress ? (
                <Button type="submit" data-test="submit">
                  Submit information
                </Button>
              ) : (
                <Loading />
              )}
            </Col>
          </Form.Row>
        </ReduxForm>
      </Layout>
    )
  }

  handleChange() {
    this.setState({
      successful: false,
    })
  }

  async handleSubmit(values) {
    if (ENVIRONMENT.name === "ssr") {
      return // no submit in SSR
    }

    this.setState({
      requestInProgress: true,
      successful: false,
    })
    const interests = Object.keys(values.interests)
      .map(key => (values.interests[key] === true ? key : null))
      .filter(value => value !== null)
    const body = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: this.props.email,
      usageType: values.usageType,
      interests,
      acceptedConditions: values.acceptedConditions,
    }

    const response = await fetch("/api/onboarding", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify(body),
    })
    if (response.ok) {
      this.setState(
        { error: false, requestInProgress: false, errorMessage: null },
        async () => {
          const result = await response.json()
          if (result.status === "ok") {
            this.setState({ error: false, successful: true })
            this.props.onBoarded({
              firstName: values.firstName,
              lastName: values.lastName,
              usageType: values.usageType,
              interests,
            })
            navigate("/installation-notes-v10")
          } else {
            this.setState({ error: true, errorMessage: await response.text() })
          }
        },
      )
    } else {
      this.setState({ error: true, requestInProgress: false, errorMessage: await response.text() })
    }
  }

  componentWillUnmount() {
    this.props.resetModel()
  }
}

function mapStateToProps(state) {
  return {
    email: state.user.email,
  }
}

function isOrangeMail(email) {
  if (email !== null && email.endsWith("orange.com")) {
    return true
  } else {
    return false
  }
}

const ConnectedOnBoardingForm = connect(mapStateToProps, dispatch => ({
  resetModel: () => dispatch(actions.reset("onboardingForm.data")),
  setValidity: (field, validations) =>
    dispatch(actions.setValidity(field, validations)),
  onBoarded: onboardingParams => dispatch(onboarded(onboardingParams)),
}))(UserForm)

export default expectAuthenticationState({ connected: true, onboarded: false })(
  ConnectedOnBoardingForm,
)
