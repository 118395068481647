import * as React from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"
import { Button } from "react-bootstrap"
import Layout from "./layout"
import NotFoundPage from "../pages/404"

export function expectAuthenticationState(expectedAuthenticationState) {
  return Component => {
    const AuthenticationWrapper = ({
      connected,
      acceptedConditions,
      admin,
      email,
      ...props
    }) => {
      if (expectedAuthenticationState.connected) {
        if (!connected) {
          return <NotFoundPage />
        }

        if (expectedAuthenticationState.onboarded && !acceptedConditions) {
          return <NotFoundPage />
        }

        if (expectedAuthenticationState.admin && !admin) {
          return <NotFoundPage />
        }
        if (expectedAuthenticationState.orangeInternal && !email.endsWith('@orange.com')) {
          return <NotFoundPage />
        }
      } else {
        if (connected) {
          return (
            <Layout>
              <h1>You are already logged in !</h1>
              <Button as={Link} variant="secondary" to="/installation-notes-v10">
                Install Khiops
              </Button>
            </Layout>
          )
        }
      }
      return <Component {...props} />
    }
    return connect(({ user: { connected, acceptedConditions, admin, email } }) => ({
      connected,
      acceptedConditions,
      admin,
      email,
    }))(AuthenticationWrapper)
  }
}
