import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import { Button } from "react-bootstrap"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not Found" />
    <h1>Page not found</h1>
    <p className="my-3">Sorry, the page you{'\''}re looking for doesn{'\''}t exist.</p>
    <Button as={Link} to="/">
      Go back to Khiops site
    </Button>
    <div className="mt-5">
      <img
        src="/nomatch.png"
        loading="lazy"
        style={{ position: "absolute", bottom: 58 }}
      />
    </div>
  </Layout>
)

export default NotFoundPage
